exports.addFilterRow = () => {
  let firstRow = $('#edm-additional-filters tbody tr:first');
  let lastRow = $('#edm-additional-filters tbody tr:last');
  let newRow = firstRow.clone();
  newRow.find(':text').val('');
  newRow.attr('data-filter-id', Date.now());
  lastRow.after(newRow);
}

exports.addFilterType = () => {
  let lastRow = $('#edm-additional-filters tbody tr:last');
  let newRow = lastRow.clone();
  newRow.find(':text').val('');
  newRow.find('td:lt(2)').empty()
  lastRow.after(newRow);
}

exports.deleteFilterRow = () => {
  let row = $(event.target).closest('tr');
  let rows = $(event.target).closest('table tbody').find('tr');
  if(rows.length == 1) {
    row.find(':text').val('');
  } else if(row.find('td:first').html() !== '') {
    rows.filter('[data-filter-id="'+row.attr('data-filter-id')+'"]').remove();
  }else {
    row.remove();
  }
}
