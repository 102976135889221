import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

window.jQuery = $;
window.$ = $;

import '../application/images/index.js.erb';
import '../application/stylesheets/index.js.erb';
export * from '../application/javascripts/index.js.erb';
